import { darkTheme, lightTheme } from "@octopusdeploy/design-system-tokens";
import type { ThemeName } from "../Theme";
export function getTheme(themeName: ThemeName) {
    switch (themeName) {
        case "light":
            return lightTheme;
        case "dark":
            return darkTheme;
        default:
            return lightTheme;
    }
}
