import { css, cx } from "@emotion/css";
import { themeTokens, text, space } from "@octopusdeploy/design-system-tokens";
import type { ReactNode } from "react";
import * as React from "react";
import { useIsUrlActive, useOctopusLinkComponent, type DesignSystemLinkHref } from "../../routing";
import { Divider } from "../Divider";
export type PageSideNavDivider = "divider";
export interface PageSideNavLink {
    label: string | JSX.Element;
    path: DesignSystemLinkHref;
    exact: boolean;
    onClick: (label: string, href: string) => void;
}
export type PageSideNavItem = PageSideNavLink | PageSideNavDivider;
export interface PageSideNavProps {
    items: PageSideNavItem[];
    tourProgress?: ReactNode;
    description?: ReactNode;
}
export function PageSideNav({ items, tourProgress, description }: PageSideNavProps) {
    return (<nav className={pageSideNavStyles}>
            {tourProgress}
            <div className={pageSideNavLinksContainerStyles}>
                {items.map((item, index) => (<div key={index}>
                        <PageSideNavItem item={item}/>
                    </div>))}
            </div>
            {description && <div className={descriptionStyles}>{description}</div>}
        </nav>);
}
function PageSideNavItem({ item }: {
    item: PageSideNavItem;
}) {
    return isPageSideNavLink(item) ? (<PageSideNavLink item={item}/>) : (<div className={dividerWrapper}>
            <Divider />
        </div>);
}
function PageSideNavLink({ item }: {
    item: PageSideNavLink;
}) {
    const Link = useOctopusLinkComponent();
    const isUrlActive = useIsUrlActive();
    const urlIsActive = isUrlActive(item.path, item.exact ? "if path matches exactly" : "if path partially matches");
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        const target = event.currentTarget;
        const label = target.innerText ?? target.getAttribute("aria-label") ?? "";
        const href = target.href;
        item?.onClick(label, href);
    };
    return (
    // eslint-disable-next-line react/forbid-elements
    <Link href={item.path} className={urlIsActive ? selectedLinkStyles : linkStyles} onClick={handleClick} disableImplicitEventTracking={true}>
            {item.label}
        </Link>);
}
export function isPageSideNavLink(item: PageSideNavItem): item is PageSideNavLink {
    return item !== "divider";
}
const pageSideNavStyles = css({
    color: themeTokens.color.text.primary,
    font: text.regular.default.medium,
    display: "flex",
    flexDirection: "column",
    flexShrink: 0,
    gap: space[16],
    padding: `${space["16"]} ${space["16"]} ${space["24"]} ${space["32"]}`,
    width: "15rem",
});
const pageSideNavLinksContainerStyles = css({
    display: "flex",
    flexDirection: "column",
    gap: space["2"],
});
const dividerWrapper = css({
    padding: `${space["16"]} 0`,
    marginLeft: space["8"],
    maxWidth: "5.125rem",
});
const descriptionStyles = css({
    padding: `${space["24"]} ${space["8"]} ${space["24"]} ${space["8"]}`,
    overflowWrap: "break-word", // The description can contain words that are too long to fit in the sidebar, we should break these words to avoid overflowing
    font: text.regular.default.small,
});
const linkStyles = css({
    color: themeTokens.color.navList.text.default,
    height: "2rem",
    display: "flex",
    alignItems: "center",
    padding: `0 ${space["8"]} 0 ${space["12"]}`,
    borderRadius: space["4"],
    ":hover": {
        color: themeTokens.color.navList.text.hover,
        backgroundColor: themeTokens.color.navList.background.hover,
    },
    ":active": {
        color: themeTokens.color.navList.text.active,
        background: themeTokens.color.navList.background.active,
    },
    ":focus-visible": {
        boxShadow: themeTokens.shadow.focused,
        outline: "none",
    },
});
const selectedLinkStyles = cx(linkStyles, css({
    color: themeTokens.color.navList.text.active,
    background: buildBackgroundGradient(themeTokens.color.border.selected, themeTokens.color.navList.background.active),
    ":hover": {
        // We need to re-apply the same styles here because we have global css trying to style this hover state
        color: themeTokens.color.navList.text.active,
        background: buildBackgroundGradient(themeTokens.color.border.selected, themeTokens.color.navList.background.active),
    },
}));
// This creates a background with a solid border on the left edge
function buildBackgroundGradient(borderColor: string, backgroundColor: string) {
    return `linear-gradient(90deg, ${borderColor} 0%, ${borderColor} ${space[4]}, ${backgroundColor} ${space[4]}, ${backgroundColor} 100%)`;
}
