import { css } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
export const GreenTickIcon = () => {
    return (<div className={style}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.0156 18C7.14062 18 4.51562 16.5 3.07812 14C1.64062 11.5312 1.64062 8.5 3.07812 6C4.51562 3.53125 7.14062 2 10.0156 2C12.8594 2 15.4844 3.53125 16.9219 6C18.3594 8.5 18.3594 11.5312 16.9219 14C15.4844 16.5 12.8594 18 10.0156 18ZM13.5469 8.53125H13.5156C13.8281 8.25 13.8281 7.78125 13.5156 7.46875C13.2344 7.1875 12.7656 7.1875 12.4844 7.46875L9.01562 10.9688L7.54688 9.5C7.23438 9.1875 6.76562 9.1875 6.48438 9.5C6.17188 9.78125 6.17188 10.25 6.48438 10.5312L8.48438 12.5312C8.76562 12.8438 9.23438 12.8438 9.54688 12.5312L13.5469 8.53125Z" fill={themeTokens.color.icon.success}/>
            </svg>
        </div>);
};
const style = css({
    width: "20px",
    height: "20px",
    display: "flex",
});
