import { css } from "@emotion/css";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import throttle from "lodash.throttle";
import * as React from "react";
import { useIsUrlActive, useOctopusLinkComponent } from "../../routing";
import { LinksMenu } from "./LinksMenu";
import type { MenuNode } from "./MenuNode";
interface PriorityNavigationProps {
    navigationItems: MenuNode[];
    maxNavigationItems: number;
}
interface PriorityNavigationState {
    itemWidths: number[] | undefined;
    priorityItems: MenuNode[];
    moreItems: MenuNode[];
    isMoreMenuOpen: boolean;
}
// eslint-disable-next-line react/no-unsafe
class PriorityNavigation extends React.Component<PriorityNavigationProps, PriorityNavigationState> {
    lastKnownContainerWidth: number | undefined = undefined;
    resizeObserver: ResizeObserver;
    constructor(props: PriorityNavigationProps) {
        super(props);
        this.resizeObserver = new ResizeObserver(throttle(this.onResize, 100));
        this.state = {
            itemWidths: undefined,
            isMoreMenuOpen: false,
            priorityItems: this.props.navigationItems,
            moreItems: [],
        };
    }
    UNSAFE_componentWillReceiveProps() {
        if (this.lastKnownContainerWidth !== undefined && this.state.itemWidths !== undefined) {
            this.updateNavigation(this.lastKnownContainerWidth, this.state.itemWidths);
        }
    }
    componentWillUnmount() {
        this.resizeObserver.disconnect();
    }
    render() {
        const hasMeasuredItemWidths = this.state.itemWidths !== undefined;
        const priorityItems = hasMeasuredItemWidths ? this.state.priorityItems : this.props.navigationItems;
        const moreItems = hasMeasuredItemWidths ? this.state.moreItems : [];
        const showMoreItems = !hasMeasuredItemWidths || moreItems.length > 0;
        return (<nav className={styles.container}>
                <ul ref={this.setNavigation} className={styles.ul}>
                    {priorityItems.map((item: MenuNode, index: number) => (<NavItem key={`navItem-${index}`} item={item}/>))}
                    {showMoreItems && <MoreNavItem moreItems={moreItems}/>}
                </ul>
            </nav>);
    }
    private howManyItemsInMenuArray = (array: number[], outerWidth: number, initialWidth: number, maximumNumberInNav: number) => {
        let total = initialWidth;
        for (let i = 0; i < array.length; i++) {
            if (i > maximumNumberInNav) {
                return maximumNumberInNav;
            }
            total += array[i];
            if (total > (outerWidth || 0)) {
                return i;
            }
        }
        return array.length;
    };
    private updateNavigation = (containerWidth: number, itemWidths: number[]) => {
        const arrayAmount = this.howManyItemsInMenuArray(itemWidths, containerWidth, itemWidths[itemWidths.length - 1], this.props.maxNavigationItems);
        const navItemsCopy = this.props.navigationItems;
        const priorityItems = navItemsCopy.slice(0, arrayAmount);
        const moreItems = priorityItems.length !== navItemsCopy.length ? navItemsCopy.slice(arrayAmount, navItemsCopy.length) : [];
        this.setState({
            priorityItems,
            moreItems,
        });
    };
    private onResize = (entries: ResizeObserverEntry[]) => {
        const navigation = entries[0].target;
        if (this.state.itemWidths === undefined) {
            const itemWidths = Array.prototype.slice.call(navigation.children).map((item: HTMLElement) => item.getBoundingClientRect().width);
            this.setState({ itemWidths }, () => {
                this.updateNavigation(navigation.clientWidth, itemWidths);
            });
        }
        else if (navigation.clientWidth !== this.lastKnownContainerWidth) {
            this.updateNavigation(navigation.clientWidth, this.state.itemWidths);
        }
        this.lastKnownContainerWidth = navigation.clientWidth;
    };
    private setNavigation = (el: HTMLUListElement) => {
        this.resizeObserver.disconnect();
        if (el !== null) {
            this.resizeObserver.observe(el);
        }
    };
    static displayName = "PriorityNavigation";
}
interface NavItemProps {
    item: MenuNode;
}
const NavItem = (props: NavItemProps) => {
    const item = props.item;
    const Link = useOctopusLinkComponent();
    const isUrlActive = useIsUrlActive();
    const urlIsActive = isUrlActive(item.url, item.exact ? "if path matches exactly" : "if path partially matches");
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        const target = event.currentTarget;
        const label = target.innerText ?? target.getAttribute("aria-label") ?? "";
        const href = target.href;
        item?.onClick(label, href);
    };
    return (<li className={styles.li}>
            {/* eslint-disable-next-line react/forbid-elements */}
            <Link href={item.url} className={urlIsActive ? styles.selectedLink : styles.link} onClick={handleClick} disableImplicitEventTracking={true}>
                <span>{item.text}</span>
            </Link>
        </li>);
};
interface MoreNavItemProps {
    moreItems: MenuNode[];
}
function MoreNavItem(props: MoreNavItemProps) {
    return (<li key="navItem-more" className={styles.li}>
            <LinksMenu className={styles.link} items={props.moreItems} label="More"/>
        </li>);
}
const styles = {
    container: css({
        overflow: "hidden",
        whiteSpace: "nowrap",
        height: "2.5rem",
        margin: 0,
        paddingBottom: 0,
    }),
    ul: css({
        margin: 0,
    }),
    li: css({
        display: "inline-block",
    }),
    link: css({
        font: text.regular.default.medium,
        color: themeTokens.color.navList.text.default,
        display: "flex",
        alignItems: "center",
        padding: `0 ${space["16"]}`,
        height: "2.5rem",
        ":hover": {
            color: themeTokens.color.navList.text.active,
        },
        ":active": {
            color: themeTokens.color.navList.text.active,
        },
    }),
    selectedLink: css({
        color: themeTokens.color.navList.text.active,
        ":hover": {
            // We need to re-apply the same styles here because we have global css trying to style this hover state
            color: themeTokens.color.navList.text.active,
        },
    }),
};
export default PriorityNavigation;
