import { css, cx } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { iconStyles } from "./iconStyles";
interface TriangleExclamationIconProps {
    size: 20 | 24;
    color?: "danger" | "warning";
}
export function TriangleExclamationIcon({ size, color }: TriangleExclamationIconProps) {
    const sizeStyles = css({
        width: size,
        height: size,
    });
    return (<svg className={cx(iconStyles, sizeStyles, { [dangerColorStyles]: color === "danger", [warningColorStyles]: color === "warning" })} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.5 9.3125L7.125 29.625C7 29.8125 7 29.9375 7 30.125C7 30.625 7.375 31 7.875 31H32.0625C32.5625 31 33 30.625 33 30.125C33 29.9375 32.9375 29.8125 32.8125 29.625L20.4375 9.3125C20.375 9.125 20.1875 9 20 9C19.75 9 19.625 9.125 19.5 9.3125ZM16.9375 7.75C17.5625 6.6875 18.75 6 20 6C21.1875 6 22.375 6.6875 23 7.75L35.375 28.0625C35.75 28.6875 36 29.375 36 30.125C36 32.25 34.25 34 32.0625 34H7.875C5.75 34 4 32.25 4 30.125C4 29.375 4.1875 28.6875 4.5625 28.0625L16.9375 7.75ZM22 27C22 28.125 21.0625 29 20 29C18.875 29 18 28.125 18 27C18 25.9375 18.875 25 20 25C21.0625 25 22 25.9375 22 27ZM21.5 15.5V21.5C21.5 22.375 20.8125 23 20 23C19.125 23 18.5 22.375 18.5 21.5V15.5C18.5 14.6875 19.125 14 20 14C20.8125 14 21.5 14.6875 21.5 15.5Z"/>
        </svg>);
}
const dangerColorStyles = css({
    fill: themeTokens.color.callout.icon.danger,
});
const warningColorStyles = css({
    fill: themeTokens.color.icon.attention,
});
