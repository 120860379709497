import { css } from "@emotion/css";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { resetStyles } from "../../utils";
import { Callout } from "../Callout";
import { ExternalLink } from "../Link";
export interface ErrorPanelProps {
    error: ErrorInfo;
}
export interface ErrorInfo {
    message: string;
    details: ErrorDetail[];
    help?: ErrorHelp;
}
interface ErrorDetail {
    text?: string;
    link?: string;
}
interface ErrorHelp {
    text?: string;
    link?: string;
}
export function ErrorPanel({ error }: ErrorPanelProps) {
    return (<Callout title={error.message} hideTitle={!error.message} type="danger">
            {error.details.length > 0 && (<ul className={errorPanelStyles.list}>
                    {error.details.map((detail, index) => detail.text || detail.link ? (<li key={index}>
                                {detail.text}
                                {detail.link && (<div>
                                        <ExternalLink href={detail.link} label="More information..."/>
                                    </div>)}
                            </li>) : null)}
                </ul>)}
            {error.help?.text && <div className={errorPanelStyles.note}>{error.help.text}</div>}
            {error.help?.link && <ExternalLink href={error.help.link} label="More information..."/>}
        </Callout>);
}
const errorPanelStyles = {
    list: css({
        ...resetStyles.ul,
        display: "flex",
        flexDirection: "column",
        gap: space[8],
        listStyleType: "disc",
        paddingLeft: space[16],
        paddingTop: space[8],
    }),
    note: css({
        marginTop: space[8],
        font: text.regular.default.xSmall,
        color: themeTokens.color.text.tertiary,
    }),
};
