import { useMediaQuery } from "react-responsive";
/**
 * These breakpoints are currently not in line with the design system.
 * These are instead in line with existing usages.
 * We need to account for these existing usages when changing these.breakpoints.
 */
export function useIsMobile() {
    return useMediaQuery({ query: `(max-width: 430px)` }); // iPhone 14 Pro Max
}
export function useIsLegacySmallScreen() {
    return useMediaQuery({ query: `(max-width: 37.4rem)` }); // @screen-sm from portal
}
export function useIsLargerThanIpadResolution() {
    return useMediaQuery({ query: `(min-width: 821px)` }); // iPad Air
}
