import { cx, css } from "@emotion/css";
import * as React from "react";
import { useCallback } from "react";
import type { MenuTargetAriaAttributes } from "../Menu";
import { SimpleMenu, useMenuState } from "../Menu";
import type { SimpleMenuItem } from "../MenuItems";
import type { MenuNode } from "./MenuNode";
interface LinksMenuProps {
    items: MenuNode[];
    label?: string | JSX.Element;
    className?: string;
}
export function LinksMenu(props: LinksMenuProps) {
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();
    // TODO: Pass a better accessible name in
    const accessibleName = "links";
    return (<>
            <LinksMenuButton onClick={openMenu} label={props.label} className={props.className} ariaAttributes={buttonAriaAttributes}/>
            <SimpleMenu menuState={menuState} items={props.items.map(convertMenuNodeToSimpleMenuItem)} accessibleName={accessibleName}/>
        </>);
}
function convertMenuNodeToSimpleMenuItem(menuNode: MenuNode): SimpleMenuItem {
    return { type: "internal-link", path: menuNode.url, label: menuNode.text, showAsActive: menuNode.exact ? "if path matches exactly" : "if path partially matches" };
}
interface LinksMenuButtonProps {
    onClick: (event: React.MouseEvent) => void;
    label?: string | JSX.Element;
    className?: string;
    ariaAttributes: MenuTargetAriaAttributes;
}
function LinksMenuButton({ label, onClick, className, ariaAttributes }: LinksMenuButtonProps) {
    const onClickCallback = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        if (event.currentTarget.contains(event.target as HTMLElement)) {
            event.preventDefault();
            onClick(event);
        }
    }, [onClick]);
    return (<a onClick={onClickCallback} href="#" className={className} {...ariaAttributes}>
            <span>{label}</span>
            <em className={cx(styles.caretIcon, "fa-solid fa-caret-down")}/>
        </a>);
}
const styles = {
    caretIcon: css({
        fontSize: "0.8rem",
        padding: "0.4rem",
    }),
};
